<template>
<!--  <div :style="`height: ${screenHeight}px;`">-->
  <div :style="`height: ${screenHeight}px;`">
    <div v-if="list.length === 0" class="no-data">
      {{ initState ? '正在加载中...' : '暂无数据' }}
    </div>
    <ul v-else v-infinite-scroll="loadMore"
        id="grid-container"
        :infinite-scroll-distance="50"
        class="infinite-list grid-container"
        @scroll="handleScroll"
        :style="`height: ${screenHeight}px; overflow: auto`">
      <div v-for="(item, index) in list" :key="index" class="grid-item">
        <img @click="handlePictureCardPreview(item)" :src="item.url"/>
      </div>
    </ul>
    <el-dialog v-model="dialogVisible" style="">
      <img style="width: 40%; height: 150%; margin-left:30%;"  @click="rotateImage" :style="{ transform: 'rotate(' + degree + 'deg)' }"  :src="dialogImageUrl" alt="Preview Image" />
      <!-- <img style="width: 600px; height: 1000px; margin-left:30%;" :src="dialogImageUrl" alt="Preview Image" /> -->
      <!-- <img style="width: 100%; height: 100%; margin-left:30%;max-height:800px;max-width:400px;" :src="dialogImageUrl" alt="Preview Image" /> -->
    </el-dialog>
  </div>
</template>

<script>
import {GetPhotos} from "@/api";
import {FileUrl} from "@/utils/def";

export default {
  name: "photoPage",
  props: {
    user_id: {
      defaultValue: -1
    }
  },
  data() {
    return {
      screenHeight: 0,
      initState: true,
      list: [],
      page: 0,
      pageSize: 100,
      userID: -1,
      fileUrl: '',
      dialogImageUrl: "",
      dialogVisible: false,
      isLoading: false,
      lastScrollTop: 0,
      isBottom: false,
      degree: 0
    }
  },
  mounted() {
    this.fileUrl = FileUrl
    this.screenHeight = document.body.clientHeight - 50
  },
  methods: {
    onShow(val) {
      this.page = 0
      this.pageSize = 100
      this.userID = val
      this.list = []
      this.load()
    },
    handleScroll(event) {
      const { target } = event
      const isScrollingDown = target.scrollTop > this.lastScrollTop
      this.lastScrollTop = target.scrollTop
      const isAtBottom = target.scrollHeight - target.scrollTop - 20 < target.clientHeight
      if (!this.isBottom && isScrollingDown && isAtBottom && !this.isLoading) {
        this.isBottom = true
        this.loadMore();
      } else if (!isAtBottom) {
        this.isBottom = false
      }
    },
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url
      this.dialogVisible = true
      this.degree=0
    },
    rotateImage() {
      // 在点击事件中将图像旋转角度增加90度
      this.degree += 90;
    },
    load() {
      this.isLoading = true
      if (this.userID < -1) {
        return
      }
      GetPhotos(
          {user_id: this.userID, page: this.page, page_size: this.pageSize}
      ).then(res => {
        for (let item of res.data.list) {
          let url = item.url
          if(!item.url.includes("aliyuncs.com")){
            url = this.fileUrl + item.url
          }

          this.list.push({
            name: item.id,
            url: url
          })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isLoading = false
        this.initState = false
      })
    },
    loadMore() {
      this.page++
      this.load()
    }
  }
}
</script>

<style scoped>

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.grid-container {
  display: grid;
   grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); 
  /* grid-gap: 10px; */
  /* grid-template-rows: 100px; */
  /* width: 20%; */
}

.grid-item {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
  max-height: 300px;
  max-width: 180px;
  
}

.no-data {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infinite-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: var(--el-color-primary-light-9);
  margin: 10px;
  color: var(--el-color-primary);
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}
</style>
